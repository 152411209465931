
import axios, { AxiosError } from 'axios';
import { AuthUser, IAuthUserService } from './AuthUser.interfaces';
const envVar = (import.meta).env

export class AuthUserService implements IAuthUserService {

    async GetAuthenticated(token: string): Promise<AuthUser> {
        const {data: user} = await axios.get<{}, {data: AuthUser}>(`${envVar.VITE_REACT_APP_AUTH_URL}/users/auth`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .catch((e: AxiosError) => {
                console.log("Error getting user by auth token: ", e)
                throw new Error("Unexpected error")
            })
        if (!user) {
            throw new Error("User not found")
        }
        return user
    }

}