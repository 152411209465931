import { AppThemeHook } from '../../../modules/themes/states/AppTheme.hook';
import classes from './spin.module.less';
import {  Spin, SpinProps } from 'antd';

export default function AppSpin(props: SpinProps) {
  const { theme } = AppThemeHook();

  return (
    <Spin {...props} className={`${classes.spin} ${theme === 'dark' && classes.dark} h-full ${props.className}`}>
      {props.children}
    </Spin>
  );

}