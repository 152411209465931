import Layout from '../layouts/admin/Layout';
import { useEffect, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { AuthHook } from '../modules/auth/states/auth/Auth.hook';
import AppSpin from '../components/ant-design/spin/spin';
import useDashboard from '../store/dashboards/useDashboard.hook';
import { AppThemeHook } from '../modules/themes/states/AppTheme.hook';
import httpClient from '../utils/http-client';
import { Tabs, TabsProps } from 'antd';
import _ from 'lodash';

const sdk = new ChartsEmbedSDK({
  baseUrl: 'https://charts.mongodb.com/charts-qpiato-perwb',
  getUserToken: () => httpClient.getToken()
});

const ConsumptionsPage = () => {
  const { currentOrganizationId, availableOrganizations } = AuthHook();
  const { dashboards: organizationDashboards } = useDashboard()
  const { theme } = AppThemeHook();

  const [tabs, setTabs] = useState<TabsProps['items']>([]);

  useEffect(() => {
    const dashboardsToPresent = organizationDashboards.filter(x => x.key.includes('consumptions.')).sort((a, b) => {
      if (!a.organization && b.organization) return 1;
      if (a.organization && !b.organization) return -1;
      return 0;
    })

    const selectedOrganization = availableOrganizations?.find(x => x._id === currentOrganizationId)

    if (currentOrganizationId && selectedOrganization && dashboardsToPresent?.length) {
      const auxTabs = dashboardsToPresent.map((dashboard) => {

        return {
          key: dashboard.key,
          label: dashboard.title,
          children: <div className='sm:flex flex-col'>
            <div className="grid grid-cols-24 lg:grid-cols-24" id={dashboard._id}>
              <AppSpin />
            </div>
          </div>,
        }
      })
      setTabs(auxTabs)

    }
  }, [currentOrganizationId, organizationDashboards])

  useEffect(() => {
    if (tabs?.length) {
      onChangeTab(tabs[0].key)
    }
  }, [tabs]);

  const onChangeTab = (key: string) => {
    const dashboard = organizationDashboards.find(x => x.key === key)
    const mongoDashboard = sdk.createDashboard({
      showAttribution: false,
      dashboardId: dashboard?.vendorId,
      height: '90vh',
      filter: {
        organization: {
          $eq: {
            "$oid": currentOrganizationId
          }
        },
      },
      theme,
      widthMode: 'scale',

    });

    setTimeout(() => {
      // render the chart into a container
      mongoDashboard
        .render(document.getElementById(dashboard?._id as string) as HTMLElement)
        .catch(() => window.alert('Dashboard failed to initialise'));
    }, 100);



  }

  return (
    <Layout hideRefreshButton title="Consumptions" subTitle="" showHeader>
      <Tabs defaultActiveKey="1" items={tabs} onChange={onChangeTab} />
    </Layout>
  );
};
export default ConsumptionsPage;
