import Axios from 'axios';
import { BrowserHistory, createBrowserHistory } from 'history';
import { message } from 'antd';

class HttpClient {
  private token: string;
  private history: BrowserHistory;
  constructor() {
    this.token = '';
    this.history = createBrowserHistory();
  }

  setToken(token: string) {
    this.token = token;
  }
  getToken(): string {
    return this.token;
  }

  clearToken() {
    this.token = '';
  }

  async get<T>(url: string, params = {}) {
    try {
      return (
        await Axios.get<any, { data: T }>(url, {
          headers: { Authorization: `Bearer ${this.token}` },
          params
        })
      ).data;
    } catch (error: any) {
      this.errorHandler(error);
    }
  }
  async post<T>(url: string, body: any) {
    try {
      return (
        await Axios.post<any, { data: T }>(url, body, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
      ).data;
    } catch (error: any) {
      this.errorHandler(error);
    }
  }
  async put<T>(url: string, body: any) {
    try {
      return (
        await Axios.put<any, { data: T }>(url, body, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
      ).data;
    } catch (error: any) {
      this.errorHandler(error);
    }
  }
  async patch<T>(url: string, body: any) {
    try {
      return (
        await Axios.patch<any, { data: T }>(url, body, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
      ).data;
    } catch (error: any) {
      this.errorHandler(error);
    }
  }
  async delete<T>(url: string) {
    try {
      return (
        await Axios.delete<any, { data: T }>(url, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
      ).data;
    } catch (error: any) {
      this.errorHandler(error);
    }
  }
  private errorHandler(error: any) {
    try {
      const messages = [...error.response.data.messages];
      message.error(messages.reduce((acc, current) => (acc += current + '\n'), ''));

      if (error.response.status === 401 || error.response.status === 403) {
        this.clearToken();
        localStorage.clear();
        this.history.push('/login', null);
      }
      throw error;
    } catch (err) {
      throw error;
    }
  }
}
const httpClient = new HttpClient();
export default httpClient;
