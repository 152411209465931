import { createContext } from "react";


interface IRefreshContext {
    refresh?: boolean;
    setRefresh?: () => void
}

const RefreshContext = createContext<IRefreshContext>({})
export default RefreshContext;
