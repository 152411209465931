import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface ISideMenuItemProps {
    urls?: string[];
    text: string;
    className?: string;
    icon: any;
    onClick?: (e: any) => void
}


export default function SideMenuItem(props: ISideMenuItemProps) {
    const pathname = window.location.pathname
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        setSelected(!!props.urls?.includes(pathname))
    }, [pathname, props.urls])

    function renderContent() {
        return (
            <li className={`
            hover:bg-white
            dark:hover:bg-gray-800
            text-red-200
            flex flex-grow justify-center items-center
            cursor-pointer w-min
        `}>
                <div onClick={props.onClick} className={`
                flex flex-col justify-center items-center
                h-20 w-20
                ${selected ? ` text-blue-600 dark:text-yellow-400` : `text-gray-600 dark:text-gray-200`}
            ${props.className}
        `}>
                    {props.icon}
                    <span className={`
                hidden sm:flex
                text-xs
                mt-2 text-center
                ${selected ? 'font-medium' : 'font-light'}
            `}>{props.text} </span>
                </div>
            </li>
        );
    }


    return (
        props.urls?.length ? (
            <Link className="flex flex-grow" to={props.urls[0]}>
                {renderContent()}
            </Link>
        ) :
            renderContent()
    );
}