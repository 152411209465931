/** Tag Version adds application tag*/

const envVar = (import.meta).env

interface ITagVersion {
    children?: any;
}

export default function TagVersion(props: ITagVersion) {

    let version = "0.0.0"
    if (envVar.REACT_APP_VERSION) {
        version =  envVar.REACT_APP_VERSION
    } else {
        console.error("Add REACT_APP_VERSION to your environment variables to display the application version")
    }

    return (
        <>
            <div className={`absolute z-50 text-xs right-0 m-1 text-gray-400 bottom-0
            `}>v.{version}</div>
            {props.children}
        </>
    )
}