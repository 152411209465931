import Layout from '../layouts/admin/Layout';
import { useEffect, useRef } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { AuthHook } from '../modules/auth/states/auth/Auth.hook';
import AppSpin from '../components/ant-design/spin/spin';
import useDashboard from '../store/dashboards/useDashboard.hook';
import { AppThemeHook } from '../modules/themes/states/AppTheme.hook';
import httpClient from '../utils/http-client';

const sdk = new ChartsEmbedSDK({
  baseUrl: 'https://charts.mongodb.com/charts-qpiato-perwb',
  getUserToken: () => httpClient.getToken()
});

const DashboardPage = () => {
  const dashboardRef = useRef<HTMLElement>(null);
  const { currentOrganizationId, availableOrganizations } = AuthHook();
  const { dashboards } = useDashboard()
  const { theme } = AppThemeHook();

  useEffect(() => {
    const dashboardId = dashboards.filter(x => x.key === 'initial-view').sort((a, b) => {
      if (!a.organization && b.organization) return 1;
      if (a.organization && !b.organization) return -1;
      return 0;
    })[0]?.vendorId

    const selectedOrganization = availableOrganizations?.find(x => x._id === currentOrganizationId)
    if (currentOrganizationId && selectedOrganization && dashboardRef.current && dashboardId) {
      const dashboard = sdk.createDashboard({
        showAttribution: false,
        dashboardId,
        height: '90vh',
        maxDataAge: 60 * 3,
        autoRefresh: true,
        filter: {
          organization: {
            $eq: {
              "$oid": currentOrganizationId
            }
          },
          // ...(selectedOrganization.lastOpenedDay ? {
          //   date: {
          //     $gte: selectedOrganization.lastOpenedDay,
          //     // $lte: currentDateRange[1].endOf('D').toDate(),
          //   }
          // } : {})

        },
        theme,
        widthMode: 'scale',

      });

      // render the chart into a container
      dashboard
        .render(dashboardRef.current)
        .catch(() => window.alert('Dashboard failed to initialise'));
    }
  }, [currentOrganizationId, dashboards])

  return (
    <Layout hideRefreshButton title="Dashboard" subTitle="" showHeader>
      <div className='sm:flex flex-col'>
        <div className="grid grid-cols-24 lg:grid-cols-24" ref={dashboardRef as any}>
          <AppSpin />
        </div>
      </div>
    </Layout>
  );
};
export default DashboardPage;
