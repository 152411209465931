import { useState } from 'react';
import Cookies from 'js-cookie';
import AuthContext from './Auth.context';
import { IAuthService } from '../../services/Auth.interfaces';
import { AuthUser, IAuthUserService } from '../../services/AuthUser.interfaces';
import httpClient from '../../../../utils/http-client';
import { OrganizationService } from '../../../../services/organization-service';
import { IOrganization } from '../../../../interfaces/organization.interface';
import { useNavigate } from 'react-router-dom';

const AUTH_COOKIE = 'auth_cookie';

// GetToken returns the token from cookie
export function GetToken(): string {
  return Cookies.get(AUTH_COOKIE) ?? '';
}

const updateCookie = (cookie: { token: string; expiresIn: number } | null) => {
  if (cookie) {
    Cookies.set(AUTH_COOKIE, cookie.token, {
      expires: cookie.expiresIn
    });
  } else {
    Cookies.remove(AUTH_COOKIE);
  }
};

interface IAuthProps {
  authService: IAuthService;
  authUserService: IAuthUserService;
  children?: any;
}

const AuthProvider = (props: IAuthProps) => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [currentOrganizationId, setCurrentOrganizationId] = useState<string>();
  const [availableOrganizations, setAvailableOrganizations] = useState<Pick<IOrganization, '_id' | 'name'>[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate()

  const getOrganizations = async (userId: string) => {
    const organizations = await OrganizationService.getUserOrganizationNames(userId)
    if (organizations && organizations[0]) {
      setAvailableOrganizations(organizations);
    }
  };

  async function login(email: string, password: string, callback: VoidFunction): Promise<void> {
    try {
      // request to api to get user & token w/ email & password
      const { token, expiresIn } = await props.authService.SignIn(email, password);

      updateCookie({ token, expiresIn });
      getAuthUser(() => {
        callback();
      });
    } finally {
    }
    return;
  }

  async function getAuthUser(callback: VoidFunction): Promise<void> {
    setLoading(true);
    try {
      const authToken = Cookies.get(AUTH_COOKIE);
      if (authToken) {
        httpClient.setToken(authToken);
      }
      if (authToken && !user) {
        const user = await props.authUserService.GetAuthenticated(authToken);
        setCurrentOrganizationId(
          Array.isArray(user.organization) ? user.organization[0] : user.organization
        );
        getOrganizations(
          user._id
        );
        setUser(user);
      }
    } catch (e) {
      logout(() => {
        navigate('/login')
      })
      console.error(e);
    } finally {
      callback();
      setLoading(false);
    }
  }

  async function logout(callback: VoidFunction) {
    try {
      setUser(null);
      updateCookie(null);
    } finally {
      callback();
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        currentOrganizationId,
        loading,
        availableOrganizations,
        logout,
        login,
        getAuthUser,
        setCurrentOrganizationId
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
