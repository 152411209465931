import { useEffect, useState } from "react";

interface IProps {
    className?: string;
    children?: any;
}

export default function Clock(props: IProps) {

    const [clockState, setClockState] = useState<Date | undefined>(new Date());

    useEffect(() => {
        setInterval(() => {
            const date = new Date()
            setClockState(date)
        }, 1000)
    }, [])

    return (
        <div className={`
        rounded-md p-1 mb-1
        flex flex-row sm:justify-between sm:flex-row
        text-blue-600 dark:text-yellow-400
        ${props.className}
        `}>
            <div className="flex flex-col text-right">
                <div className="font-medium text-xl hidden sm:flex">
                    {clockState?.toLocaleTimeString() ?? '00:00:00'}
                </div>
                <div className="text-xs sm:text-md pr-5 sm:pr-0">
                    <span>{clockState?.toLocaleString('en-US', { day: 'numeric' }) ?? '-'} </span>
                    <span>{clockState?.toLocaleString('en-US', { month: 'short' }) ?? '-'} </span>
                    <span>{clockState?.toLocaleString('en-US', { year: 'numeric' }) ?? '-'}</span>
                </div>
            </div>
            {props.children}
        </div>

    );
}