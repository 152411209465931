import axios from 'axios';
import httpClient from '../../../utils/http-client';
import { AuthResponse, IAuthService } from './Auth.interfaces';
const envVar = (import.meta).env

export class AuthService implements IAuthService {

  async SignIn(email: string, password: string): Promise<{ token: string; expiresIn: number }> {
    const { data: resp } = await axios.post<
      { email: string; password: string },
      { data: AuthResponse }
    >(`${envVar.VITE_REACT_APP_AUTH_URL}/auth`, { email, password });
    httpClient.setToken(resp.token);
    return resp;
  }
}
