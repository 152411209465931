import React, { useContext } from 'react'

import useDashboardHandler from './useDashboard.hook'
import { IDashboard } from '../../interfaces/dashboard.interface'

export interface DashboardContextInterface {
  getDashboards: (organizationId: string) => Promise<IDashboard[]>
  loading: boolean
  dashboards: IDashboard[]
  clearState: () => void
}

export const dashboardContext = React.createContext<DashboardContextInterface | undefined>(undefined)

const { Provider } = dashboardContext

export function useDashboardContext(): DashboardContextInterface {
  const contextValue = useContext(dashboardContext)
  if (contextValue === undefined) {
    throw new Error('Context must be inside a Provider')
  }
  return contextValue
}

interface ProviderProps {
  children: React.ReactNode
}

const DashboardProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const dashboardAcessors = useDashboardHandler()
  return <Provider value={dashboardAcessors}>{children}</Provider>
}

export default DashboardProvider
