import Layout from '../layouts/admin/Layout';
import { useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { AuthHook } from '../modules/auth/states/auth/Auth.hook';
import AppSpin from '../components/ant-design/spin/spin';
import useDashboard from '../store/dashboards/useDashboard.hook';
import { AppThemeHook } from '../modules/themes/states/AppTheme.hook';
import httpClient from '../utils/http-client';
import { Tabs, TabsProps } from 'antd';
import _ from 'lodash';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import AppDatePicker from '../components/ant-design/date-picker/date-picker';

// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault('Europe/Lisbon');


const sdk = new ChartsEmbedSDK({
  baseUrl: 'https://charts.mongodb.com/charts-qpiato-perwb',
  getUserToken: () => httpClient.getToken()
});

const EarningsPage = () => {
  const { currentOrganizationId, availableOrganizations } = AuthHook();
  const { dashboards: organizationDashboards } = useDashboard()
  const [currentDateRange, setCurrentDateRange] = useState<[Dayjs, Dayjs] | undefined>([dayjs().subtract(1, 'days').startOf('D'), dayjs().endOf('D')]);

  const { theme } = AppThemeHook();

  const [tabs, setTabs] = useState<TabsProps['items']>([]);

  const tabKey = useRef<string>()

  const [timeFilterEnabled, setTimeFilterEnabled] = useState(false);
  const [timeFilterRange, setTimeFilterRange] = useState<string | undefined>('15-D');

  useEffect(() => {
    const dashboardsToPresent = organizationDashboards.filter(x => x.key.includes('custom.')).sort((a, b) => {
      if (!a.organization && b.organization) return 1;
      if (a.organization && !b.organization) return -1;
      return 0;
    })

    const selectedOrganization = availableOrganizations?.find(x => x._id === currentOrganizationId)

    if (currentOrganizationId && selectedOrganization && dashboardsToPresent?.length) {
      const auxTabs = dashboardsToPresent.map((dashboard) => {

        return {
          key: dashboard.key,
          label: dashboard.title,
          children: <div className='sm:flex flex-col'>
            <div className="grid grid-cols-24 lg:grid-cols-24" id={dashboard._id}>
              <AppSpin />
            </div>
          </div>,
        }
      })
      setTabs(auxTabs)

    }
  }, [currentOrganizationId, organizationDashboards])

  useEffect(() => {
    if (tabs?.length) {
      onChangeTab(tabs[0].key)
    }
  }, [tabs]);

  const onChangeTab = (key: string) => {
    tabKey.current = key
    const dashboard = organizationDashboards.find(x => x.key === key)
    setTimeFilterEnabled(!!dashboard?.enableTimeFilter)
    setTimeFilterRange(dashboard?.maxTimeRangeFilter)

    const mongoDashboard = sdk.createDashboard({
      showAttribution: false,
      dashboardId: dashboard?.vendorId,
      height: '80vh',

      filter: {
        organization: {
          $eq: {
            "$oid": currentOrganizationId
          }
        },
        ...(currentDateRange && !!dashboard?.enableTimeFilter ? {
          date: {
            $gte: currentDateRange[0].toDate(),
            $lte: currentDateRange[1].endOf('D').toDate(),
          }
        } : {})
      },
      theme,
      widthMode: 'scale',

    });

    setTimeout(() => {
      // render the chart into a container
      mongoDashboard
        .render(document.getElementById(dashboard?._id as string) as HTMLElement)
        .catch(() => window.alert('Dashboard failed to initialise'));
    }, 100);

  }

  useEffect(() => {
    if (tabKey.current) {
      onChangeTab(tabKey.current)
    }
  }, [currentDateRange]);

  return (
    <Layout hideRefreshButton title="Earnings Dashboard" subTitle="" showHeader>
      {currentOrganizationId &&
        <>
          {timeFilterEnabled ? <div className={`sm:flex flex-col sm:items-end`}>
            <AppDatePicker
              tooltip
              minDate={dayjs().subtract(timeFilterRange?.split('-')?.at(0) ? Number(timeFilterRange?.split('-')?.at(0)) : 15, (timeFilterRange?.split('-')?.at(1) as ManipulateType) ?? 'days').startOf('D')}
              maxDate={dayjs().endOf('D')}
              defaultValue={currentDateRange}
              onChange={([start, end]: [Dayjs, Dayjs]) => {
                setCurrentDateRange([dayjs(start), dayjs(end)])
              }}
            />
          </div> : ''}
          <Tabs defaultActiveKey="1" items={tabs} onChange={onChangeTab} />

        </>
      }
    </Layout>
  );
};
export default EarningsPage;
