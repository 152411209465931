import { AppThemeHook } from '../../../modules/themes/states/AppTheme.hook';
import classes from './select.module.less';
import { Select } from 'antd';

export default function AppSelect(props: any) {
  const { theme } = AppThemeHook();

  return (
    <Select
      {...props}
      popupClassName={`${theme === 'dark' && classes.dark} ${classes.dropdown}  ${props.popupClassName}`}
      className={`${classes.select} ${theme === 'dark' && classes.dark} ${props.className}`}
    >
      {props.children}
    </Select>
  );

}