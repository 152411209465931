import { IDashboard } from '../interfaces/dashboard.interface';
import httpClient from '../utils/http-client';
const envVar = (import.meta).env
export class DashboardService {
  // TODO change hardcoded values and use api data
  static async fetchOrganizationDashboards(
    organizationId: string,
  ): Promise<IDashboard[]> {
        return await httpClient.get(
      `${envVar.VITE_REACT_APP_API}/dashboards?organizationId=${organizationId}`
    ) as IDashboard[];
  }

}
