import { AuthHook } from '../../modules/auth/states/auth/Auth.hook';
import { CashIcon, CogIcon, ChartIcon, LogoutIcon, CollectionIcon } from '../../components/icons';
import SideMenuItem from './SideMenuItem';
import { useNavigate } from 'react-router-dom';
import useDashboard from '../../store/dashboards/useDashboard.hook';
import { useMemo } from 'react';
import { BarChartOutlined } from '@ant-design/icons'

export default function SideMenu() {
  const { logout } = AuthHook();
  const navigate = useNavigate();

  const { dashboards } = useDashboard()

  const MENU_ITEMS = useMemo(() => {

    const customDashboard = dashboards.find(x => x.key.includes('custom.'))
    return [
      { urls: ['/', '/dashboard'], text: 'Dashboard', icon: ChartIcon },
      ...(customDashboard ? [{ urls: ['/custom-dashboard'], text: 'Custom Dashboards', icon: ChartIcon }] : []),
      { urls: ['/earnings'], text: 'Earnings', icon: CashIcon },
      { urls: ['/orders'], text: 'Products', icon: CollectionIcon },
      { urls: ['/consumptions'], text: 'Consumptions', icon: <BarChartOutlined style={{ fontSize: '24px' }} /> },
      { urls: ['/settings'], text: 'Settings', icon: CogIcon }
    ]
  }, [dashboards])

  async function onClickLogout() {
    if (logout) {
      try {
        await logout(() => {
          navigate('/login');
        });
      } catch (e) {
        console.error('Error ocurred when logout', e);
      }
    }
  }

  return (
    <aside
      className={`
            flex flex-row sm:flex-col
            flex-grow
            justify-center
            bg-gray-200
            dark:bg-gray-900 dark:text-gray-200
            transition-colors duration-800 h-full
        `}
    >
      <ul
        className={`
                h-full w-full
                flex flex-column sm:justify-around
                flex-grow sm:block overflow-auto
            `}
      >
        {MENU_ITEMS.map(({ icon, text, urls }) => (
          <SideMenuItem key={urls[0]} urls={urls} text={text} icon={icon} />
        ))}
      </ul>
      <ul className="mb-0">
        <SideMenuItem
          className={`
                    hidden sm:flex
                    text-red-600 dark:text-red-400
                    hover:bg-red-400 hover:text-white
                    dark:hover:text-white
                `}
          text="Logout"
          icon={LogoutIcon}
          onClick={onClickLogout}
        />
      </ul>
    </aside>
  );
}
