import Content from './Content';
import SideMenu from './SideMenu';
import { AppThemeHook } from '../../modules/themes/states/AppTheme.hook';

import { AuthHook } from '../../modules/auth/states/auth/Auth.hook';
import Clock from '../../components/costum/clock';
import RefreshButton from '../../components/costum/refresh-button';
import AppSelect from '../../components/ant-design/select/select';
import { Select } from 'antd';
const { Option } = Select;

interface ILayoutProps {
  title?: string;
  subTitle?: string;
  children: any;
  showHeader?: boolean;
  hideRefreshButton?: boolean;
  subMenu?: any;
}

export default function Layout({ ...props }: ILayoutProps) {
  const { theme } = AppThemeHook();
  const { availableOrganizations, setCurrentOrganizationId, currentOrganizationId } = AuthHook();

  return (
    <div className={` ${theme} flex flex-col sm:flex-row-reverse h-screen w-full`}>
      <div className={` flex flex-col flex-grow
                        overflow-auto
                        bg-gray-100 dark:bg-gray-800
                        transition-colors duration-1000
                        `}
      >
        {
          props.showHeader && <div className='w-full md:px-7 sm:h-auto sm:relative flex flex-col items-center align-middle
              sm:flex-row sm:justify-between
              flex-grow-0
             dark:bg-gray-900 transition-colors duration-1000
             bg-gray-200
             sm:bg-transparent dark:sm:bg-transparent sm:w-auto
            '>
            {availableOrganizations && availableOrganizations.length > 1 ? (
              <AppSelect
                className='w-full text-center sm:w-auto text-2xl sm:text-3xl'
                defaultValue={currentOrganizationId ?? availableOrganizations[0]?._id}
                onChange={(id: any) => {
                  setCurrentOrganizationId && setCurrentOrganizationId(id);
                }}
              >
                {availableOrganizations.map(({ name, _id }) => (
                  <Option key={_id} value={_id}>{name}</Option>
                ))}
              </AppSelect>
            ) : (
              <div className=' text-blue-700 dark:text-yellow-400 text-xl bolder'>
                {availableOrganizations ? availableOrganizations[0].name : ''}
              </div>
            )
            }
            <Clock>
              {!props.hideRefreshButton &&
                <RefreshButton
                  className="hidden sm:flex sm:m-2"
                  longPollingSeconds={5 * 60}
                  debounceSeconds={2}
                />
              }
            </Clock>
          </div>
        }
        <Content className='flex flex-grow overflow-auto md:px-7' {...props}>
          {props.children}
        </Content>
      </div>
      <div className='flex flex-col sm:flex-row-reverse flex-grow-0'>
        <div className='sm:hidden'>
          {props.subMenu && props.subMenu}
        </div>
        <SideMenu />
      </div>
    </div>
  );
}
