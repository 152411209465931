import { createContext } from "react";


export interface IAppThemeContext {
    theme?: 'dark' | 'light';
    changeTheme?: () => void
}

const AppThemeContext = createContext<IAppThemeContext>({})
export default AppThemeContext;

