import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthHook } from '../states/auth/Auth.hook';

export default function AuthGuard() {

    const { user, loading, getAuthUser } = AuthHook();
     /* Got loading image at: https://loading.io/ */

     useEffect(() => {
        getAuthUser && getAuthUser(()=>{})
     }, [loading])

    function renderLoading() {
        return (
            <div className={`
            flex justify-center items-center h-screen
            bg-gray-800
            `}>
                <img src="/images/loading.gif" alt="loading" />
            </div>
        )
    }

    if (!loading) {
        if (!user) {
             return <Navigate to={'/login'} replace />;
        } else {
            return <Outlet />
        }
    } else {
        return renderLoading();
    }
}