import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthGuard from './modules/auth/components/AuthGuard';
import DashboardPage from './pages/Dashboard.page';
import LoginPage from './pages/Login.page';
import CustomDashboard from './pages/CustomDashboard.page';
import ConsumptionsPage from './pages/Consumptions.page';
import ProductsPage from './pages/Products.page';
const EarningsPage = React.lazy(() => import('./pages/Earnings.page'));
const NotFound = React.lazy(() => import('./pages/NotFound.page'));
const SettingsPage = React.lazy(() => import('./pages/Settings.page'));

const WrapperSuspense = ({children}: any)=>{

  return (
    <Suspense fallback={<Spin />}>
      {children}
    </Suspense>
  )
}
const AppRoutes = ()=> {
  return (
    <Routes>
        <Route path="/login" element={
        <WrapperSuspense>
          <LoginPage />
        </WrapperSuspense> } />
        <Route element={<AuthGuard/>}>
          <Route index  element={
              <WrapperSuspense>
              <DashboardPage />
            </WrapperSuspense>
          }/>
          <Route path="/dashboard" element={
          <WrapperSuspense>
            <DashboardPage />
          </WrapperSuspense>
          } />
          <Route path="/custom-dashboard" element={
          <WrapperSuspense>
            <CustomDashboard />
          </WrapperSuspense>
          } />
          <Route path="/earnings" element={
          <WrapperSuspense>
            <EarningsPage />
          </WrapperSuspense>

          } />
          <Route path="/orders" element={
          <WrapperSuspense>
            <ProductsPage />
          </WrapperSuspense>
          } />
          <Route path="/consumptions" element={
          <WrapperSuspense>
            <ConsumptionsPage />
          </WrapperSuspense>
          } />
          <Route path="/settings" element={
          <WrapperSuspense>
            <SettingsPage />
          </WrapperSuspense>
          } />
        </Route>
        <Route path="*" element={<NotFound redirectTo='/home' delay={2000} />} />
      </Routes>
  );
}

export default AppRoutes