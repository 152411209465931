interface IContentProps {
    children?: any;
    subMenu?: any;
    className?: string;
}

export default function Content(props: IContentProps) {
    return (
        <div className={`
            flex flex-col sm:mt-2
            dark:text-gray-200
            ${props.className}
        `}>
            {props.children}
        </div>
    );
}