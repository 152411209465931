import { useCallback, useEffect, useState } from "react";
import { RefreshHook } from "../../modules/refresh/Refresh.hook";
import { RefreshIcon } from "../icons";

interface IProps {
    longPollingSeconds?: number; // ms for longPolling
    debounceSeconds: number;
    className?: string;
}

// refreshButton with or without longPolling
export default function RefreshButton(props: IProps) {

    const { setRefresh } = RefreshHook();

    const [disabled, setDisabled] = useState(false);

    const throttleAndRefresh = useCallback(() => {
        setDisabled(true)
        setRefresh?.()
        const interval = setInterval(() => {
            setDisabled(false)
            clearInterval(interval)
        }, props.debounceSeconds * 1000)
        return () => clearInterval(interval);
    }, [props.debounceSeconds, setRefresh])


    useEffect(() => {
        if (props.longPollingSeconds) {
            const interval = setInterval(() => {
                throttleAndRefresh()
            }, props.longPollingSeconds * 1000)
            return () => clearInterval(interval)
        }
    }, [props.longPollingSeconds, throttleAndRefresh])

    return <button className={`
    w-min h-min
    rounded-full p-1
    text-white
    bg-blue-500 dark:bg-yellow-500
    disabled:bg-gray-300 dark:disabled:bg-gray-300
    ${disabled ? "motion-safe:animate-reverse-spin" : ""}
    ${props.className}
    `}
        onClick={throttleAndRefresh} disabled={disabled}>
        {RefreshIcon}
    </button>
}