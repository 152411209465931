import React from 'react';
import { AppThemeHook } from '../../../modules/themes/states/AppTheme.hook';
import classes from './date-picker.module.less';
import { DatePicker, Tooltip } from 'antd';
import type { CellRenderInfo } from "rc-picker/es/interface";
import { Dayjs } from 'dayjs';

export default function AppDatePicker(props: any) {
  const { theme } = AppThemeHook();

  const cellRender = React.useCallback(
    (current: number | Dayjs, info: CellRenderInfo<Dayjs>) => {

      if (info.type !== "date") {
        return <Tooltip title="If you want to extend the date please upgrade your plan">{info.originNode}</Tooltip>;
      }

      if (typeof current === "number") {
        return (
          <Tooltip title="If you want to extend the date please upgrade your plan">
            <div className="ant-picker-cell-inner">{current}</div>
          </Tooltip>
        );
      }

      return (
        <Tooltip title="If you want to extend the date please upgrade your plan">
          <div className="ant-picker-cell-inner">{current.date()}</div>
        </Tooltip>
      );
    },
    []
  );

  return <DatePicker.RangePicker
    {...props}
    cellRender={props.tooltip ? cellRender : undefined}
    popupClassName={`${classes.dropdown} ${theme === 'dark' && 'dark'} ${props.popupClassName}`}
    className={`${classes.select} ${theme === 'dark' && classes.dark} ${props.className}`}
  />;
}