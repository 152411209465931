import { IOrganization } from '../interfaces/organization.interface';
import httpClient from '../utils/http-client';
const envVar = (import.meta).env

export class OrganizationService {
  static async getOrganization(organizationID: string): Promise<IOrganization | undefined> {
    return httpClient.get(`${envVar.VITE_REACT_APP_API}/organizations/${organizationID}`);
  }
  static async getUserOrganizationNames(userId: string): Promise<Pick<IOrganization, '_id' | 'name'>[] | undefined> {
    return httpClient.get(`${envVar.VITE_REACT_APP_API}/users/${userId}/organizations`);
  }
}
