import {  useState } from "react";
import RefreshContext from "./Refresh.context";


// Provider
const RefreshProvider = (props: any) => {
    const [refresh, updateRefresh] = useState<boolean>(()=> {
        return true;
    });

    function setRefresh() {
        updateRefresh((current) => {
            return !current;
        })
    }

    return (
        <RefreshContext.Provider value={{
            refresh,
            setRefresh
        }}>
            {props.children}
        </RefreshContext.Provider>
    )
}
export default RefreshProvider;