import { createContext } from 'react';
import { IOrganization } from '../../../../interfaces/organization.interface';
import { AuthUser } from '../../services/AuthUser.interfaces';

interface IAuthContext {
  user?: AuthUser | null;
  currentOrganizationId?: string;
  loading?: boolean;
  availableOrganizations?: Pick<IOrganization, '_id' | 'name'>[];
  setCurrentOrganizationId?: (id: string) => void;
  login?: (email: string, password: string, callback: VoidFunction) => void;
  logout?: (callback: VoidFunction) => void;
  getAuthUser?: (callback: VoidFunction) => void;
}

const AuthContext = createContext<IAuthContext>({ availableOrganizations: [] });
export default AuthContext;
