import { useEffect, useState } from 'react'

import { IDashboard } from '../../interfaces/dashboard.interface'
import { DashboardService } from '../../services/dashboards-service'
import { AuthHook } from '../../modules/auth/states/auth/Auth.hook'
import { DashboardContextInterface } from './dashboard.context'

const useDashboard = (): DashboardContextInterface => {
  const [loading, setLoading] = useState(false)
  const [dashboards, setDashboards] = useState<IDashboard[]>([])
  const { currentOrganizationId } = AuthHook()


  const getDashboards = async (organizationId: string): Promise<IDashboard[]> => {
    setLoading(true)
    const result = await DashboardService.fetchOrganizationDashboards(organizationId)
    setLoading(false)

    setDashboards(result)

    return result
  }


  const clearState = () => { setDashboards([]) }

  useEffect(() => {
    if (currentOrganizationId) {
      getDashboards(currentOrganizationId)
    } else {
      setDashboards([])
    }
  }, [currentOrganizationId])




  return {
    dashboards,
    clearState,
    loading,
    getDashboards

  }
}

export default useDashboard
