import { useState } from 'react';
import AppContext, { IAppThemeContext } from './AppTheme.context';

// Provider
const AppThemeProvider = (props: any) => {
  const [theme, setTheme] = useState<IAppThemeContext['theme']>(() => {
    const theme = localStorage.getItem('theme') as IAppThemeContext['theme'];
    return theme ?? undefined;
  });

  function changeTheme() {
    setTheme((current) => {
      const theme = !current ? 'dark' : 'light';
      localStorage.setItem('theme', theme);
      return theme;
    });
  }

  return (
    <AppContext.Provider
      value={{
        theme,
        changeTheme
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
export default AppThemeProvider;
