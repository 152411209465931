import { BrowserRouter } from 'react-router-dom';

import './App.less';
import 'antd/dist/reset.css';
// import '@ant-design/flowchart/dist/index.css';
import { IAuthService } from './modules/auth/services/Auth.interfaces';
import { AuthService } from './modules/auth/services/Auth.service';
import { IAuthUserService } from './modules/auth/services/AuthUser.interfaces';
import { AuthUserService } from './modules/auth/services/AuthUser.service';
import AuthProvider from './modules/auth/states/auth/Auth.provider';
import AppThemeProvider from './modules/themes/states/AppTheme.provider';
import AppRoutes from './Routes';
import RefreshProvider from './modules/refresh/Refresh.provider';
import DashboardProvider from './store/dashboards/dashboard.context';

const App = () => {
  const authService: IAuthService = new AuthService();
  const authUserService: IAuthUserService = new AuthUserService();

  return (
    <AppThemeProvider>
      <RefreshProvider>
        <BrowserRouter>
          <AuthProvider authService={authService} authUserService={authUserService}>
            <DashboardProvider>
              <AppRoutes />
            </DashboardProvider>
          </AuthProvider>
        </BrowserRouter>
      </RefreshProvider>
    </AppThemeProvider>
  );
};

export default App;
