import { useState } from "react";
import { message, Form, Input, Button, Spin } from 'antd'; // FIXME: Remove
import TagVersion from "../modules/core/components/tag-version";
import { AuthHook } from "../modules/auth/states/auth/Auth.hook";
import { AppThemeHook } from "../modules/themes/states/AppTheme.hook";
import { useNavigate } from "react-router-dom";



function LoginPage() {
    const appTheme = AppThemeHook();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { login } = AuthHook()

    function showError(msg: string) {
        message.error(msg, 3)
    }

    async function submit() {
        setLoading(true)
        if (login) {
            try {
                await login(email, password, () => {
                    navigate("/dashboard")
                })
            } catch (e: any) {
                showError(e.message)
                setLoading(false)
            }
        }
    }

    async function onFinishFailed(e: any) {
        showError(e.errorFields[0].errors[0])
    }

    return (
        <TagVersion>
            <div className={`${appTheme.theme}`}>
                <div className={`
                flex h-screen w-auto flex-col items-center justify-center
                bg-gray-100 dark:bg-gray-800
            `}>
                    {
                        loading ? <Spin size="large" /> :
                            <Form
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onFinish={submit}
                                onFinishFailed={(e) => onFinishFailed(e)}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    initialValue={email}
                                    noStyle={true}
                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                                >
                                    <Input autoComplete="current-email" type={'email'} onChange={(data)=> {setEmail(data.target.value as string)}}/>
                                </Form.Item>
                                <div className="mt-2">
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        noStyle={true}
                                        initialValue={password}
                                        rules={[{ required: true, message: 'Please input your password!' }]}
                                    >
                                        <Input.Password autoComplete="current-password" onChange={(data)=> {setPassword(data.target.value as string)}} />
                                    </Form.Item>
                                </div>
                                <Form.Item noStyle={true} wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button className="mt-2 w-full bg-blue-500 text-white" htmlType="submit">
                                        Sign in
                                    </Button>
                                </Form.Item>
                            </Form>
                    }
                </div>
            </div>
        </TagVersion>
    )
}

export default LoginPage